import { createRouter, createWebHistory } from 'vue-router'

import { isMobile } from '@/utils/utils';

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/RegisterView.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPasswordView.vue')
  },
  // 主应用路由
  {
    path: '/app',
    component: () => import('@/views/app/AppLayout.vue'),
    children: [
      {
        path: 'home',
        name: 'Home',
        // component: () => import('@/views/app/HomeView.vue')
        component: () => import('@/views/app/LocationView.vue')
      },
      {
        path: 'location',
        name: 'AppLocation',
        component: () => import('@/views/app/LocationView.vue')
      },
      {
        path: '/detail',
        name: 'LocationDetail',
        component: () => import('@/views/app/LocationDetailView.vue')
      },
      {
        path: 'flag',
        name: 'Flag',
        component: () => import('@/views/app/FlagListView.vue')
      },
      {
        path: 'message',
        name: 'AppMessage',
        component: () => import('@/views/app/MessageView.vue')
      },
      {
        path: 'profile',
        name: 'AppProfile',
        component: () => import('@/views/app/ProfileView.vue')
      },
      {
        path: 'change-password',
        name: 'ChangePassword',
        component: () => import('@/views/app/ChangePassword.vue')
      },
      {
        path: 'profile-edit',
        name: 'ProfileEdit',
        component: () => import('@/views/app/ProfileEditView.vue')
      },
      {
        path: 'group-info',
        name: 'GroupInfoView',
        component: () => import('@/views/app/GroupInfoView.vue')
      },
      {
        path: 'addLocation',
        name: 'AddLocation',
        component: () => import('@/views/app/AddLocation.vue')
      },
      {
        path: '',
        redirect: 'home'
      }
      // ... 其他应用内路由
    ]
  },
  {
    path: '/:pathMatch(.*)*', // 匹配所有路径
    name: 'NotFound',
    component: () => import('../views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 路由守卫：根据终端类型跳转
router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    if (isMobile()) {
      next('/mobile');  // 修改这里，直接跳转到 /mobile 路径
    } else {
      next('/desktop');  // 为了保持一致性，也修改为路径形式
    }
  } else {
    next();
  }
});

export default router
