import {createStore} from 'vuex'
import createPersistedState from 'vuex-persistedstate';

export default createStore({
    state: {
        cityName: '',
        cityCode: 0,
    },
    getters: {
        getCityName: (state) => state.cityName,
        getCityCode: (state) => state.cityCode,
    },
    mutations: {
        setCityName(state, city) {
            state.cityName = city
        },
        setCityCode(state, region) {
            state.cityCode = region
        },
    },
    actions: {},
    modules: {},
    plugins: [
        createPersistedState({
            storage: window.localStorage, // 这里使用 localStorage 来持久化存储
        }),
    ],
})
