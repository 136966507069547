import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import TDesign from 'tdesign-mobile-vue';
import 'tdesign-mobile-vue/es/style/index.css';
const app = createApp(App);



// // 全局注册所有图标
// Object.keys(Icons).forEach((key) => {
//     app.component(key, Icons[key]);
// });

// 全局注册所有图标
// Object.keys(Icons).forEach((key) => {
//     app.component(key, (Icons as Record<string, any>)[key]);
// });

app.use(store).use(router).use(TDesign).mount('#app')

